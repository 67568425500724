import React, { lazy } from 'react';

const ActivityComponent = lazy(
  () => import('components/userInformation/activity/content/ActivityContent'),
);
const HistoryComponent = lazy(
  () => import('components/userInformation/history/content/HistoryContent'),
);
const LocationComponent = lazy(
  () => import('components/userInformation/location/content/LocationContent'),
);
const ReferralComponent = lazy(
  () => import('components/userInformation/activity/content/RefferalSource/ReferralSource'),
);
const TechnicalComponent = lazy(
  () => import('components/userInformation/technicalSpecs/content/TechnicalSpecsContent'),
);
const VisitorDataComponent = lazy(
  () => import('components/userInformation/visitorData/content/VisitorDataContent'),
);
const VideoComponent = lazy(
  () =>  import('components/userInformation/video/content/VideoContent'),
)

export const componentMap: { [key: string]: React.FC } = {
  ActivityComponent: ActivityComponent,
  HistoryComponent: HistoryComponent,
  LocationComponent: LocationComponent,
  ReferralComponent: ReferralComponent,
  TechnicalComponent: TechnicalComponent,
  VisitorDataComponent: VisitorDataComponent,
  VideoComponent: VideoComponent
};
